import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { medWrapper, H1Green, buttonThree } from "../../styles/helpers"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Story from "./Story"
gsap.registerPlugin(ScrollTrigger)

const CommunityStoriesSection = styled.section`
  .wrapper {
    ${medWrapper};
  }

  .mainTitle {
    width: 100%;
    padding: 8rem 2rem;
    margin: auto;
    background-color: rgba(197, 231, 222, 0.25);
    text-align: center;

    h2 {
      ${H1Green};
      margin: 0;
    }
  }

  .link {
    padding: 2rem;
    padding-bottom: 5rem;
    text-align: center;
    a {
      ${buttonThree};
    }
  }

  .botBackground {
    width: 100%;
    padding: 2rem;
    background-color: rgba(197, 231, 222, 0.25);
  }
`

const CommunityStories = props => {
  const stories = props.stories.edges
  const trigger = useRef(null)

  useEffect(() => {
    const allBlocks = [...document.querySelectorAll(".storyblock")]

    gsap.set(allBlocks, { y: 100, autoAlpha: 0 })

    gsap.to(allBlocks, {
      y: 0,
      autoAlpha: 1,
      duration: 1,
      stagger: {
        each: 0.25,
      },
      scrollTrigger: {
        trigger: trigger.current,
        markers: false,
        scrub: false,
        start: "top 50%",
      },
    })
  }, [])

  return (
    <CommunityStoriesSection>
      <div className="mainTitle">
        <h2>Community Stories</h2>
      </div>
      <div ref={trigger} className="wrapper">
        {stories.map((story, index) => {
          return <Story story={story} key={index} namedClass="storyblock" />
        })}
      </div>
      <div className="link">
        <Link to="/community-life/testimonials-stories">Read More Stories</Link>
      </div>
      <div className="botBackground" />
    </CommunityStoriesSection>
  )
}

export default CommunityStories

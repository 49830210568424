import React, { useState } from "react"
import BgImg from "gatsby-background-image"
import styled from "styled-components"

import {
  H2White,
  colors,
  B1White,
  fontSizer,
  buttonThree,
} from "../../styles/helpers"
import { Link } from "gatsby"

const AmenityItem = styled.div`
  position: relative;
  width: 100%;
  height: 100vw;
  border: 0.1rem solid ${colors.white};

  @media (min-width: 768px) {
    height: 50rem;
  }

  @media (min-width: 1025px) {
    height: 33.33vw;
  }

  .mainTitle {
    position: absolute;
    bottom: 2.5rem;
    left: 2rem;
    transition: all 0.3s ease;
    z-index: 5;

    @media (max-width: 1024px) {
      opacity: 0;
      visibility: hidden;
    }

    @media (min-width: 1025px) {
      opacity: ${props => (props.isActive ? "0" : "1")};
      visibility: ${props => (props.isActive ? "hidden" : "visable")};
    }

    h2 {
      ${H2White};
    }
  }

  .hoverContent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    opacity: 1;
    visibility: visible;
    z-index: 10;

    @media (min-width: 1025px) {
      opacity: ${props => (props.isActive ? "1" : "0")};
      visibility: ${props => (props.isActive ? "visable" : "hidden")};
    }

    &__container {
      width: 100%;
      max-width: 40rem;
      margin: auto;

      p {
        ${B1White};
        margin: 0;
      }
    }

    &__title {
      border-bottom: 0.1rem solid ${colors.white};

      h2 {
        ${H2White};
        margin: 0;
        text-align: center;
      }
    }

    &__link {
      margin-bottom: 4rem;
      text-align: center;

      @media (max-width: 1024px) {
        margin-top: 4rem;
      }

      a {
        @media (max-width: 1024px) {
          ${buttonThree};
        }

        @media (min-width: 1025px) {
          ${B1White};
          ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
          text-transform: uppercase;
        }

        span {
          display: none;
          padding-right: 0.5rem;
          @media (min-width: 1025px) {
            display: inline-block;
          }
        }
      }
    }
  }

  .bgImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`

const Amenity = ({ amenity }) => {
  const [isActive, setIsActive] = useState(false)
  const handleHover = () => {
    setIsActive(true)
  }
  const handleHoverLeave = () => {
    setIsActive(false)
  }
  const slug =
    amenity.title === "Character Homes"
      ? "/pick-your-home"
      : "/community-life/amenities-map"

  return (
    <AmenityItem
      isActive={isActive}
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverLeave}
    >
      <div className="mainTitle">
        <h2>{amenity.title}</h2>
      </div>
      <div className="hoverContent">
        <div className="hoverContent__container">
          <div className="hoverContent__title">
            <h2>{amenity.title}</h2>
          </div>
          <div className="hoverContent__link">
            <Link to={`${slug}`}>
              {" "}
              <span>&gt;</span>Learn More
            </Link>
          </div>
          <div dangerouslySetInnerHTML={{ __html: amenity.content }} />
        </div>
      </div>
      <div className="bgImage">
        <BgImg
          tag="div"
          fluid={amenity.image.localFile.childImageSharp.fluid}
        />
      </div>
    </AmenityItem>
  )
}

export default Amenity

import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Amenity from "./Amenity"
import { colors, fonts, fontSizer } from "../../styles/helpers"

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  fade: false,
  draggable: true,
  infinite: true,
  speed: 250,
  autoplay: false,
  autoplaySpeed: 15000,
  centerMode: true,
  centerPadding: "150px",
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "20px",
      },
    },
  ],
}

const AmenitiesSection = styled.section`
  position: relative;
  padding: 1rem 0 10rem;

  @media (min-width: 768px) {
    padding: 1rem 0 5rem;
  }

  .slick-slider {
    .slick-arrow {
      top: auto;
      right: auto;
      bottom: -5rem;
      left: auto;
      z-index: 500;

      &::before {
        ${fontSizer(4, 6, 76.8, 150, 4)};
        transition: 0.3s ease-in all;
        font-family: ${fonts.fontAwesome};
        color: ${colors.greyBrown};
      }

      &:hover {
        &::before {
          color: ${colors.colorSecondary};
        }
      }
    }

    .slick-prev {
      left: calc(50% - 10rem);

      &::before {
        content: "\f177";
      }
    }

    .slick-next {
      right: calc(50% - 5rem);

      &::before {
        content: "\f178";
      }
    }
  }
`

const Amenities = ({ amenities }) => {
  return (
    <AmenitiesSection>
      <Slider {...settings}>
        {amenities.acf._coo_hoam_amenities.map((amenity, index) => {
          return <Amenity key={index} amenity={amenity} />
        })}
      </Slider>
    </AmenitiesSection>
  )
}

export default Amenities

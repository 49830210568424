import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { Link } from "gatsby"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  BigWrapper,
  colors,
  H1Green,
  B1Black,
  B1Green,
} from "../../styles/helpers"
import CoopersCategory from "../Icons/CoopersCategory"
import { createSlug } from "../../utils/helperFunc"

gsap.registerPlugin(ScrollTrigger)

const BlockStyle = styled.div`
  ${BigWrapper};
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  align-items: center;

  @media (min-width: 768px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  @media (min-width: 1024px) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 5rem;
    z-index: 10;

    @media (min-width: 768px) {
      position: absolute;
      width: 50%;
      max-width: 63.4rem;
      height: 70vw;
      min-height: 50rem;
      max-height: 63.4rem;
      top: 50%;
      right: ${props => (props.isReversed ? "auto" : "45%")};
      left: ${props => (props.isReversed ? "45%" : "auto")};
      margin-bottom: 0;
      transform: translateY(-50%);
    }

    @media (min-width: 1024px) {
      width: 50%;
      max-width: 63.4rem;
      height: 100vw;
      max-height: 63.4rem;
    }

    &__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background-color: ${colors.white};
      padding: 5rem 2rem;

      @media (min-width: 768px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5rem;
      }

      &--wrap {
      }
    }

    &__category {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      margin-bottom: 2.5rem;

      &--icon {
        width: 5rem;
      }
      h3 {
        ${B1Green}
        margin: 0;
        padding-left: 1rem;
        text-transform: uppercase;
        line-height: 1;
      }
    }

    &__title {
      width: 100%;
      margin-bottom: 4.5rem;
      padding-bottom: 4.5rem;
      border-bottom: 0.1rem solid ${colors.colorSecondary};

      h2 {
        ${H1Green};
        margin: 0;
      }
    }

    &__para {
      width: 100%;
      p {
        ${B1Black};
      }
    }

    &__link {
      width: 100%;
      a {
        ${B1Green};
        text-transform: uppercase;

        span {
          padding-right: 0.5rem;
        }

        &:hover {
          color: ${colors.colorShad};
        }
      }
    }

    &__background {
      position: absolute;
      top: 3rem;
      left: -3rem;
      width: 100%;
      height: 100%;
      background-color: ${colors.colorAlt};
      opacity: 0.44;
      z-index: -1;
    }
  }

  .image {
    position: relative;
    width: 100%;
    height: calc(100vw - 4rem);
    max-height: 40rem;

    @media (min-width: 768px) {
      width: calc(75%);
      height: calc(75vw - 2rem);
      max-height: 90rem;
    }

    @media (min-width: 1024px) {
      width: 75%;
      height: calc(75vw - 2rem);
      max-height: 90rem;
      margin-right: ${props => (props.isReversed ? "25%" : "auto")};
      margin-left: ${props => (props.isReversed ? "auto" : "25%")};
    }

    @media (min-width: 1600px) {
      max-height: 90rem;
    }

    &__background {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
    }
  }
`

const Block = ({ block, isReversed }) => {
  const content = useRef(null)
  const image = useRef(null)
  const trigger = useRef(null)

  useEffect(() => {
    gsap.set(content.current, { y: 100, autoAlpha: 0 })
    gsap.set(image.current, { x: 100, autoAlpha: 0 })

    const tl = gsap.timeline()
    tl.to(content.current, {
      y: 0,
      autoAlpha: 1,
      duration: 1,
    }).to(image.current, {
      x: 0,
      autoAlpha: 1,
      duration: 1,
    })

    ScrollTrigger.create({
      animation: tl,
      trigger: trigger.current,
      markers: false,
      scrub: false,
      start: "top 50%",
    })
  }, [])

  const linkUrl = createSlug(block.link)

  return (
    <BlockStyle ref={trigger} className="wrapper" isReversed={isReversed}>
      <div ref={content} className="content">
        <div className="content__inner">
          <div className="content__inner--wrap">
            <div className="content__category">
              <div className="content__category--icon">
                <CoopersCategory />
              </div>
              <h3>{block.category}</h3>
            </div>
            <div className="content__title">
              <h2>{block.title}</h2>
            </div>
            <div
              className="content__para"
              dangerouslySetInnerHTML={{ __html: block.content }}
            />
            <div className="content__link">
              <Link to={`/${linkUrl}`}>
                <span>&gt;</span>
                {block.button_text}
              </Link>
            </div>
          </div>
        </div>
        <div className="content__background" />
      </div>
      <div ref={image} className="image">
        <BGImg
          tag="div"
          className="image__background"
          fluid={block.image.localFile.childImageSharp.fluid}
        />
      </div>
    </BlockStyle>
  )
}

export default Block

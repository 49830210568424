import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { fontSizer, B1Black } from "../../styles/helpers"

const StoryStyle = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  @media (min-width: 768px) {
    width: calc(33.33% - 2rem);
    margin: 1rem;
  }

  @media (min-width: 1025px) {
  }

  .image {
    width: 100%;
    box-shadow: 6px 7px 7px 0 rgba(0, 0, 0, 0.16);
  }

  .excerpt {
    position: relative;
    padding: 3rem 1rem 0rem;
    text-align: center;

    p {
      ${B1Black};
      margin: 0;
    }

    p.excerpt__quote {
      ${fontSizer(2, 7.5, 76.8, 150, 2)}
      position: absolute;
      top: 0.5rem;
      right: 0;
      left: 0;
      width: 100%;
      line-height: 1;
    }
  }
`

const Story = ({ story, namedClass }) => {
  const trigger = useRef(null)
  const image = useRef(null)
  const content = useRef(null)

  return (
    <StoryStyle ref={trigger} className={namedClass}>
      <div ref={image} className="image">
        <Img
          fluid={
            story.node.acf._coo_cost_featured_image.localFile.childImageSharp
              .fluid
          }
          alt={story.node.acf._coo_cost_featured_image.alt_text}
        />
      </div>
      <div ref={content} className="excerpt">
        <p className="excerpt__quote">”</p>
        <p className="excerpt__content">
          “{story.node.acf._coo_cost_story_excerpt}”
        </p>
      </div>
    </StoryStyle>
  )
}

export default Story

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const CoopersCategoryWrap = styled.div`
  width: 100%;
  height: 100%;
`

const getData = graphql`
  {
    coopersCategory: file(relativePath: { eq: "coopers-category.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 125) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const CoopersCategory = () => {
  const data = useStaticQuery(getData)
  const imageData = data.coopersCategory.childImageSharp.fluid
  return (
    <CoopersCategoryWrap>
      <Img fluid={imageData} alt="Coopers Crossing Category" />
    </CoopersCategoryWrap>
  )
}

export default CoopersCategory

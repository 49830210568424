import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {
  standardWrapper,
  H1Green,
  B1Black,
  buttonThree,
  colors,
} from "../../styles/helpers"
import { createSlug } from "../../utils/helperFunc"
gsap.registerPlugin(ScrollTrigger)

const ContentBlockSection = styled.section`
  padding: 0;

  @media (min-width: 768px) {
    padding: 5rem 0;
  }

  @media (min-width: 1025px) {
    padding: 10rem 0;
  }

  .wrapper {
    ${standardWrapper};
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .cbContent {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      width: calc(50%);
      margin-top: 0;
      margin-bottom: 0;
    }

    @media (min-width: 1025px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }

    h2 {
      ${H1Green};
    }

    p {
      ${B1Black};
    }

    a {
      ${buttonThree};
    }
  }

  .cbImage {
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }

    @media (min-width: 1025px) {
      width: calc(50% - 4rem);
      margin-left: 4rem;
    }

    &__image {
      position: relative;
    }

    &__background {
      position: absolute;
      top: 2rem;
      left: -2rem;
      width: 100%;
      height: 100%;
      background-color: ${colors.colorAlt};
      opacity: 0.6;
      z-index: -1;
    }
  }
`

const ContentBlock = ({ contentBlock }) => {
  const slug = createSlug(contentBlock.acf._coo_conblo_button_link)

  const trigger = useRef(null)
  const content = useRef(null)
  const image = useRef(null)

  useEffect(() => {
    gsap.set(content.current, { autoAlpha: 0, x: -100 })
    gsap.set(image.current, { autoAlpha: 0, x: 100 })

    const tl = gsap.timeline()

    tl.to([content.current, image.current], {
      duration: 1,
      autoAlpha: 1,
      x: 0,
      stagger: {
        each: 0.5,
      },
    })

    ScrollTrigger.create({
      animation: tl,
      trigger: trigger.current,
      markers: false,
      scrub: false,
      start: "top 50%",
    })
  }, [])

  return (
    <ContentBlockSection>
      <div ref={trigger} className="wrapper">
        <div ref={content} className="cbContent">
          <div>
            <h2>{contentBlock.acf._coo_conblo_title}</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: contentBlock.acf._coo_conblo_content,
            }}
          />
          <div>
            <Link to={`/${slug}`}>
              {contentBlock.acf._coo_conblo_button_text}
            </Link>
          </div>
        </div>
        <div ref={image} className="cbImage">
          <div className="cbImage__image">
            <Img
              fluid={
                contentBlock.acf._coo_conblo_image.localFile.childImageSharp
                  .fluid
              }
              alt={contentBlock.acf._coo_conblo_title}
            />
            <div className="cbImage__background" />
          </div>
        </div>
      </div>
    </ContentBlockSection>
  )
}

export default ContentBlock

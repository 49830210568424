import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import Hero from "../components/Home/Hero"
import SimpleContent from "../components/Home/SimpleContent"
import Amenities from "../components/Home/Amenities"
import ContentBlock from "../components/Home/ContentBlock"
import HeroCallout from "../components/Home/HeroCallout"
import Blocks from "../components/Home/Blocks"
import HeroCallout2 from "../components/Home/HeroCallout2"
import CommunityStories from "../components/Home/CommunityStories"

const IndexPage = props => {
  const {
    seoInfo,
    hero,
    simple,
    amenities,
    contentBlock,
    heroCall,
    blocks,
    heroCall2,
    stories,
  } = props.data

  const location = props.location.pathname

  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location}
      />
      <Hero hero={hero} />
      <SimpleContent simple={simple} />
      <Amenities amenities={amenities} />
      <ContentBlock contentBlock={contentBlock} />
      <HeroCallout heroCall={heroCall} />
      <Blocks blocks={blocks} />
      <HeroCallout2 heroCall={heroCall2} />
      <CommunityStories stories={stories} />
    </Layout>
  )
}

export const homeQuery = graphql`
  {
    seoInfo: wordpressPage(slug: { eq: "home" }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_content
        _cco_hohr_button_required
        _coo_hohr_btn_text
        _coo_hohr_page_link_two
        _coo_hohr_bot_intro
      }
    }

    simple: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_simp_title
        _coo_simp_content
      }
    }

    amenities: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_hoam_amenities {
          title
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    contentBlock: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_conblo_title
        _coo_conblo_content
        _coo_conblo_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_conblo_button_required
        _coo_conblo_button_text
        _coo_conblo_button_link
      }
    }

    heroCall: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_hercal_title
        _coo_hercal_button_text
        _coo_hercal_button_link
        _coo_hercal_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1614) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    blocks: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_contblos_content_blocks {
          category
          title
          content
          link_required
          link
          button_text
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    heroCall2: wordpressPage(slug: { eq: "home" }) {
      acf {
        _coo_hercal_title_2
        _coo_hercal_button_text_2
        _coo_hercal_button_link_2
        _coo_hercal_image_2 {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1614) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    stories: allWordpressAcfCommunityStories(limit: 3) {
      edges {
        node {
          acf {
            _coo_cost_name
            _coo_cost_community_story
            _coo_cost_story_excerpt
            _coo_cost_featured_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
